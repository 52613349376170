var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        {
          staticClass: "searchWrapper",
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.submitData.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-position": "right",
                "label-width": "150px",
                rules: _vm.rules,
                model: _vm.formInline,
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    border: "1px solid #C0CCDA",
                    "border-radius": "4px",
                    padding: "15px",
                  },
                },
                [
                  _c("h2", { staticClass: "title" }, [
                    _c("div", { staticClass: "title_icon" }),
                    _c("span", [_vm._v("模板配置")]),
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: "12" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动头图:",
                                prop: "headPhoto",
                                required: "",
                              },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  staticClass: "avatar-uploader",
                                  attrs: {
                                    name: "image",
                                    "show-file-list": false,
                                    "on-success": _vm.handleAvatarSuccess,
                                    "before-upload": _vm.beforeAvatarUpload,
                                    headers: _vm.uploadHeader,
                                    action: "/acb/2.0/systems/loginUser/upload",
                                  },
                                },
                                [
                                  _vm.formInline.headPhoto
                                    ? _c("img", {
                                        staticClass: "avatar",
                                        attrs: {
                                          src: _vm.formInline.headPhoto,
                                        },
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-plus avatar-uploader-icon",
                                      }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "背景颜色:",
                                prop: "backgroundColor",
                              },
                            },
                            [
                              _c("el-color-picker", {
                                model: {
                                  value: _vm.formInline.backgroundColor,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formInline,
                                      "backgroundColor",
                                      $$v
                                    )
                                  },
                                  expression: "formInline.backgroundColor",
                                },
                              }),
                              _c("span", [
                                _vm._v(_vm._s(_vm.formInline.backgroundColor)),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "文字颜色:", prop: "fontColor" },
                            },
                            [
                              _c("el-color-picker", {
                                model: {
                                  value: _vm.formInline.fontColor,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formInline, "fontColor", $$v)
                                  },
                                  expression: "formInline.fontColor",
                                },
                              }),
                              _c("span", [
                                _vm._v(_vm._s(_vm.formInline.fontColor)),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "600px" },
                              attrs: { label: "活动说明:", prop: "content" },
                            },
                            [
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    rows: 6,
                                    placeholder: "请输入活动说明",
                                    maxlength: "200",
                                    "show-word-limit": "",
                                  },
                                  model: {
                                    value: _vm.formInline.content,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formInline, "content", $$v)
                                    },
                                    expression: "formInline.content",
                                  },
                                }),
                              ],
                            ],
                            2
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否允许用户分享:",
                                prop: "share",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 0 },
                                  model: {
                                    value: _vm.formInline.share,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formInline, "share", $$v)
                                    },
                                    expression: "formInline.share",
                                  },
                                },
                                [_vm._v("是")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 1 },
                                  model: {
                                    value: _vm.formInline.share,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formInline, "share", $$v)
                                    },
                                    expression: "formInline.share",
                                  },
                                },
                                [_vm._v("否")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: "12" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "preview",
                            style: {
                              backgroundColor: _vm.formInline.backgroundColor,
                            },
                          },
                          [
                            _c("div", { staticClass: "head-photo" }, [
                              this.headPhoto
                                ? _c("img", { attrs: { src: this.headPhoto } })
                                : _vm._e(),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "containner",
                                style: {
                                  color: _vm.formInline.fontColor,
                                  backgroundColor:
                                    _vm.formInline.backgroundColor,
                                },
                              },
                              [
                                _c("div", { staticClass: "please-input" }, [
                                  _vm._v("请输入手机号领取优惠券"),
                                ]),
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c("input", {
                                      staticClass: "phone-input",
                                      staticStyle: { width: "100%" },
                                      attrs: { placeholder: "请输入手机号" },
                                    }),
                                    _c("div", { staticClass: "phone-button" }, [
                                      _vm._v("获取验证码"),
                                    ]),
                                  ]
                                ),
                                _c("input", {
                                  staticClass: "phone-input",
                                  attrs: { placeholder: "请输入短信验证码" },
                                }),
                                _c(
                                  "button",
                                  {
                                    staticClass: "receive",
                                    attrs: { disabled: "" },
                                  },
                                  [_vm._v("立即领取")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "activity-description" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "description-title" },
                                      [_vm._v("活动说明")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "description-content" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.formInline.content) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm.formInline.share === 0
                    ? _c(
                        "div",
                        [
                          _c("h2", { staticClass: "title" }, [
                            _c("div", { staticClass: "title_icon" }),
                            _c("span", [_vm._v("分享配置")]),
                          ]),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "分享标题:", prop: "shareTitle" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "500px" },
                                attrs: {
                                  placeholder: "请输入分享标题",
                                  maxlength: "30",
                                },
                                model: {
                                  value: _vm.formInline.shareTitle,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formInline, "shareTitle", $$v)
                                  },
                                  expression: "formInline.shareTitle",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "分享文案:",
                                prop: "shareContent",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "500px" },
                                attrs: {
                                  placeholder: "请输入分享文案",
                                  maxlength: "50",
                                },
                                model: {
                                  value: _vm.formInline.shareContent,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formInline,
                                      "shareContent",
                                      $$v
                                    )
                                  },
                                  expression: "formInline.shareContent",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "分享图片:",
                                prop: "sharePhoto",
                                required: "",
                              },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  staticClass: "avatar-uploader",
                                  attrs: {
                                    name: "image",
                                    "show-file-list": false,
                                    "on-success": _vm.handleAvatarSuccess1,
                                    "before-upload": _vm.beforeAvatarUpload,
                                    headers: _vm.uploadHeader,
                                    action: "/acb/2.0/systems/loginUser/upload",
                                  },
                                },
                                [
                                  _vm.formInline.sharePhoto
                                    ? _c("img", {
                                        staticClass: "avatar",
                                        attrs: {
                                          src: _vm.formInline.sharePhoto,
                                        },
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-plus avatar-uploader-icon",
                                      }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "margin-top": "20px" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "88px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.submitData },
                },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "88px" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }